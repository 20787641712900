import React from "react"
import { Link } from "gatsby"
import main3 from '../images/gmlogo.png';
import edit from '../images/edit.jpg';
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReferenceCard from "../components/ReferenceCard.js"
import { Parallax } from 'react-scroll-parallax';
const references = () => (
  <Layout>
    <SEO title="Rólunk" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">
    
<div className="mr-auto bg-blue-300 ml-auto  -mt-10"><Parallax className="custom-class" y={[-20, 40]} tagOuter="figure">
<img src={main3} alt="Logo" className="mr-auto ml-auto mainimg1 " /></Parallax></div>


<div className="left2 mt-20 "><h3 className="maintext2 left2 ">Üdvözöljük honlapunkon!</h3>
<h3 className="maintext2 mt-2 text-center">
A vizsgálatokat és a szemüvegeket készíti:</h3>
<div className="flex   items-center pb-6">
  <div>

<h3 className="maintext2 mt-2   text-center "> Szamosvári Edit 
 </h3> 
<h3 className="maintext2 mt-2 text-center ">látszerész, optometrista, kontaktológus.
</h3>
</div>
<div><img src={edit} alt="Szamosvári Edit " className="  h-auto w-1/2  m-auto shadow-2xl" /></div>
</div>
<h3 className="maintext2 mt-2 left2 text-left"> A látásjavító eszközök (szemüveg, kontaktlencse) vizsgálatánál, elkészítésénél a legfontosabb szempont az egyedi igények figyelembe vétele!


 </h3> 
 <h3 className="maintext2 mt-2 left2 text-left mt-20">Fontosnak tartjuk, hogy amivel jól lát, abban jól is érezze magát!


</h3> 


<h3 className="maintext2 mt-2 left2 text-left"> 1996-ban a Práter utcai Szakmunkásképző Intézetben végeztem, mint látszerész.</h3> 
<h3 className="maintext2 mt-2 left2 text-left"> Ezután évekig műhelyesként dolgoztam, szemüvegeket készítettem. 2005-ben diplomáztam a SOTE Főiskolai karán, optometrista szakon.
 </h3> 
<h3 className="maintext2 mt-2 left2 text-left">Hivatásomban azt szeretem, hogy a vizsgálattól kezdve, a keret és lencse kiválasztásán keresztül az elkészítést is én végzem el. Összetett és izgalmas feladat az elejétől végigkísérni egy új szemüveg születését!

</h3> 




</div></div>

    </div></div>
  </Layout>
)

export default references
