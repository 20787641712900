import React from "react"


class ReferenceCard extends React.Component{

constructor(props){
super(props)
this.state = {

    OpenClose : false



}
}
render(){
    return(<>
<div><h1>lol</h1></div>

    </>
    )
}

















}

export default ReferenceCard